import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import BGImg1 from '../../assets/images/resources/chart.gif'
import bg3 from '../../assets/images/resources/data.gif'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import img1 from '../../assets/images/resources/img1.jpg'
import team from '../../assets/images/resources/team.jpg'
import p1 from '../../assets/images/projects/ml.jpg'
import p2 from '../../assets/images/projects/ai.jpg'
import p3 from '../../assets/images/projects/secure.jpg'
import p4 from '../../assets/images/projects/bd.jpg'
import p5 from '../../assets/images/projects/ep.jpg'




const Home = () => {

    const [ytShow, setytShow] = useState(false)

    const sliderOptions = {
        loop: true,
        items: 1,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        margin: 0,
        dots: true,
        nav: true,
        animateOut: "slideOutDown",
        animateIn: "fadeIn",
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false
    }
    const projectOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 0,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            }
        }
    }


    return (
        <>
            <div className="slider-one">
                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
                    <div className="row spc">
                        <div className="slide-cont col-md-6">
                            <div className="slider-one__content ">

                                <p className="slider-one__text">Unlock new possibilities</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Discover valuable insights by tapping into <br />
                                    data with ML and AI</h2>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                        <img src={bg3} alt="" />
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        </div>                     
                    </div>
                    <div className="row">
                        <div className="slide-cont col-md-6">
                            <div className="slider-one__content ">

                                <p className="slider-one__text">Unlock new possibilities</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Make informed decisions <br />
                                    through Business Intelligence</h2>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                        <img src={BGImg1} alt="" />
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        </div>                     
                    </div>
                </OwlCarousel>
            </div>
            <section className="sub_menu">
			<div className="container">

				<div className="row gutter-y-30">
					<div className="col-lg-3 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><a href="#service">Popular Services</a></h3>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><a href="#projects">Latest Projects</a></h3>
							</div>
						</div>
					</div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><a href="#about">Product Partnership</a></h3>
							</div>
						</div>
					</div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
						<div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
							<div className="team-card-one__content">
								<h3 className="team-card-one__title"><a href="#our_service">Why you should choose us?</a></h3>
							</div>
						</div>
					</div>                   					
					
					
                 
					
				</div>
			</div>
		</section>
       


            <section
                className=" service-four gray-bg section-padding--bottom section-padding--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                style={{ backgroundImage: `url(${BGImg3})` }}>
                <div className="container">
                    <div class="row section-padding--b">
                    <div className="section-title text-center">
                        <p className="section-title__text">Popular Services</p>
                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-analysis"></i>
                                </div>
                                <Link to="/data-analytics"><div className="service-card-three__content">
                                    <h3 className="service-card-three__title">Data
                                        <br />
                                         Analytics</h3>
                                    <div className="service-card-three__text">Enable to achieve exponential business outcomes.
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-new-product"></i>
                                </div>
                                <Link to="/data-analytics-eco-system"> <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">Building <br/> Data Analytics
                                        <br />
                                        Eco-system</h3>
                                    <div className="service-card-three__text">To modernize your data management foundation.
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-cloud"></i>
                                </div>
                                <Link to="/cloud-solution"><div className="service-card-three__content">
                                    <h3 className="service-card-three__title">Cloud  
                                        <br />
                                         Solution</h3>
                                    <div className="service-card-three__text">Accelerate cloud adoption and transformation journey.
                                    </div>
                                    
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-cloud"></i>
                                </div>
                                <Link to="/consulting"><div className="service-card-three__content">
                                    <h3 className="service-card-three__title">Consulting</h3>
                                    <div className="service-card-three__text">Build capabilities and drive transformations.
                                    </div>
                                </div>
                                </Link>
                            </div>

                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-protection"></i>
                                </div>
                                <Link to="/cyber-security"><div className="service-card-three__content">
                                    <h3 className="service-card-three__title">Cyber <br />
                                         Security</h3>
                                    <div className="service-card-three__text">Improve readiness for auditing and stay compliant.
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className="item col-md-2">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-analysis"></i>
                                </div>
                                <Link to="/robotic-process-automation"><div className="service-card-three__content">
                                    <h3 className="service-card-three__title">RPA                                        <br />
                                       </h3>
                                    <div className="service-card-three__text">Purpose-built automated solution to address business problem.
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>      

            <section className="section-padding--top project-one" id="projects">
                <div className="container">
                    <div className="project-one__top">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="section-title ">
                                    <p className="section-title__text">Latest Projects/PoC</p>
                                    
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="project-one__top__content">
                                    <p className="project-one__top__text"></p>
                                    <div className="project-one__top__btns">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <OwlCarousel className="thm-owl__carousel project-one__carousel thm-owl__carousel--with-shadow" {...projectOptions}>
                        <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img className="" src={p1} alt="" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        
                                        <h3 className="project-card-one__title">Machine Learning(ML) against Bots</h3>
                                       <br/> <p className="project-card-one__text">Machine Learning(ML) model against Bots is to identify bad Bots and take proactive actions to secure devices by classifying IoT traffic as benign or malicious.</p>
                                        <p className="project-card-one__more"> <i className="fa fa-angle-right"></i></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img className="" src={p2} alt="" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                    <h3 className="project-card-one__title">Enhanced premise security system</h3>                                      
                                    <br/><p className="project-card-one__text">Alert when an unauthorized person enters premises outside of office hours, by processing  CCTV footages using image processing  with ML.</p>
                                        <p className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img className="" src={p3} alt="" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                    <h3 className="project-card-one__title">Detection of Drive-by attacks</h3>                                       
                                    <br/>  <p className="project-card-one__text">As the number of websites in the world grows, so do the chances of having vulnerable websites. Organizations can use this ML model to potentially detect and stop drive-by attacks that can causes serious damages to organization.</p>
                                        <p className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img className="" src={p4} alt="" />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                    <h3 className="project-card-one__title">Building data analytics Eco-system end-to-end</h3>                                       
                                    <br/>  <p className="project-card-one__text">Where we successfully built Data Lake, ETL, Data warehousing, Business Intelligence reporting, Machine Learning model processes with ML OPS in AWS and Azure.</p>
                                        <p className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img className="" src={p5} alt="" />
                                </div>
                              <Link to="/purchase-requisition-enterprise-application">
                                 <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                    <h3 className="project-card-one__title">Purchase requisition enterprise application</h3>                                       
                                    <p className="project-card-one__text"></p>
                                        <p className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </p>
                                    </div>
                                </div>
                                </Link> 
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>

      
            <section className="section-padding--b about-five" id="about">
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-6">
                            <div className="about-five__images">
                                <div className="about-five__images__shape"></div>
                                <img src={img1} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                                

                                <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                    <span className="count-text">Core Team</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Us</p>
                                </div>
                                <div className="about-five__text">Tesla Digitech was founded in 2022 by parent company Tesla Electrical 
                                for the purpose of providing customers with value and benefit derived from advanced data analytics, 
                                data eco-systems, machine learning and business intelligence reporting.</div>
                                <div className="about-five__text">We provide end to end digital transformation of your data. Our focus is quality, 
                                security, and cost reduction.</div>
                                <div className="about-five__text">We enable greater decision-making power through valuable predictions and
                                 insights derived from your structured and unstructured data.</div>
                                 <div className="section-title ">
                                    <br /><p className="section-title__text">Mission</p>
                                    <p className="about-five__text">Our mission is to accelerate our customers business growth with advanced data analytics and data eco-systems that 
                                leverage machine learning and business intelligence reporting.</p>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        
                    <ul className="about-five__box">
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon fa fa-eye"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title"><Link to="">Vision</Link></h3>
                                            <p className="about-five__box__text">Our vision is to offer enhanced data-driven experience for our customers that help them grow, evolve, and better serve their clients, 
                                                by digitizing the core and making analytics possible for even small and medium-sized firms, which enable them to unlock new possibilities and write success stories.</p>
                                        </div>
                                    </li>
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon fa fa-line-chart"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title"><Link to="">Growth</Link></h3>
                                            <p className="about-five__box__text">We value the growth of our customers, partners, and employees.</p>
                                        </div>
                                    </li>

                                </ul>
                                <ul className="about-five__box">
                                <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon fa fa-user-group"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title"><Link to="">Integrity</Link></h3>
                                            <p className="about-five__box__text">Trust is the foundation on which we build our business. This means having open, honest communication whilst modelling high ethics and moral values.</p>
                                        </div>
                                    </li>
                                     <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon fa fa-sack-dollar"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title"><Link to="">Invest</Link></h3>
                                            <p className="about-five__box__text">We invest our effort in solving data needs and challenges that lead to efficiency, security, and cost reduction.</p>
                                        </div>
                                    </li>


                                    
                                </ul>
                    </div>
                </div>
            </section>

            <section className=" section-padding--top about-three about-three--home-one section-padding--bottom"id="our_service">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-three__image">
                                <img src={team} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-three__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Company Benefits</p>
                                    <h2 className="section-title__title">Why You Should Choose
                                        Our Services</h2>
                                </div>

                                <ul className="about-three__list">
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Get the insights you need to grow your business.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Make analytics possible even to small and medium size organization.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Modernize your data foundation and adopt data migration to cloud.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Workflow Orchestration.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Increase productivity, reduce cost, and speed up time to market.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Adopt good data governance.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Build capabilities and drive transformation to have competitive advantage.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Improved customer service.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Purpose-built products to solve your business problems.
                                    </li>
                                    <li className="about-three__list__item">
                                    <i class="fa fa-check-circle" aria-hidden="true"></i> Better advertising and marketing.
                                    </li>
 
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="cta-one">
                <div className="container">
                    <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
                        <div className="cta-one__circle"></div>
                        <div className="section-title ">
                            <p className="section-title__text">Share your business problem to get the solution you need</p>
                            <h2 className="section-title__title section-title__title--light">Let’s Work Together on Project</h2>
                        </div>
                        <Link to="/contact-us" className="thm-btn thm-btn--light cta-one__btn"><span>Start Now</span></Link>
                    </div>
                </div>
            </section>
            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        <iframe src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1" title='ytvideo' allowFullScreen></iframe>
                    </div>
                </div>}
        </>
       
    )
}

export default Home
