import React from 'react'
import BG2 from '../../assets/images/shapes/testi-bg-1-1.png'
import Breadcumb from '../Breadcumb/Main'
import img1 from '../../assets/images/resources/img3.jpg'


const About = () => {
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <Breadcumb Title="About Us" Breadcumb="ABOUT" />
            <section
                className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"
                style={{ backgroundImage: `url(${BG2})` }}>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-5">
                            <div className="testimonials-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Unlock New Possibilities with</p>
                                    <h2 className="section-title__title">Tesla DigiTech </h2>
                                </div>
                                <div>
                                <img src={img1} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                               </div>
                                <br /><div className="testimonials-two__content__text">Tesla Digitech was founded in 2022 by parent company Tesla Electrical for the purpose of providing customers with value and benefit derived from advanced data analytics, data eco-systems, machine learning and business intelligence reporting.</div>
                                <div className="testimonials-two__content__text">We provide end to end digital transformation of your data. Our focus is quality, security, and cost reduction.</div>
                                <div className="testimonials-two__content__text">We enable greater decision-making power through valuable predictions and insights derived from your structured and unstructured data.</div>
                                <div className="section-title ">
                                    <p className="section-title__text">MISSION</p>
                                </div>
                                <div className="testimonials-two__content__text">Our mission is to accelerate our customers business growth with advanced data analytics and data eco-systems that leverage machine learning and business intelligence reporting.</div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonials-two__items">
                                <div className="row gutter-y-30">
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                            <i className='far fa-eye'></i>
                                            </div>
                                            <div className="testimonials-one-card__content">
                                            <h3 className="testimonials-one-card__title">Vision</h3>
                                                <div className="testimonials-one-card__text">Our vision is to offer enhanced data-driven experience for our customers that help them grow, evolve, and better serve their clients, 
                                                by digitizing the core and making analytics possible for even small and medium-sized firms, which enable them to unlock new possibilities and write success stories.</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                    <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                            <i className='	fa fa-line-chart'></i>
                                            </div>
                                            <div className="testimonials-one-card__content">
                                            <h3 className="testimonials-one-card__title">Growth</h3>
                                                <div className="testimonials-one-card__text">We value the growth of our customers, partners, and employees.</div>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                    <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                            <i className='fa fa-user-group'></i>
                                            </div>
                                            <div className="testimonials-one-card__content">
                                            <h3 className="testimonials-one-card__title">Integrity</h3>
                                                <div className="testimonials-one-card__text">Trust is the foundation on which we build our business. This means having open, honest communication whilst modelling high ethics and moral values.</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                    <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                            <i className="fa fa-sack-dollar"></i>
                                            </div>
                                            <div className="testimonials-one-card__content">
                                            <h3 className="testimonials-one-card__title">Invest</h3>
                                                <div className="testimonials-one-card__text">We invest our effort in solving data needs and challenges that lead to efficiency, security, and cost reduction.</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            
        </>
    )
}

export default About