import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/es.jpg'

const EcoSystem = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Data-Analytics Eco system" Breadcumb="SERVICES" />
				<section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
								<h3 className="service-details__title">Data-Analytics Eco system</h3>
								<div className="service-details__content">
									<p>Explore how data Lakes allow you to run analytics without the need to move your data to a separate analytics system and to generate valuable insights including historical data ignored in the past. </p>
									
									<p>Uncover hidden patterns, correlations, sentiment analysis and customer personalized recommendation from large-scale data in structured and semi-structured data with EMR and transform data to fit to analytical purpose.</p>
									<p>Securely and centrally manage access to available datasets with fine-grained security capabilities.</p>
								</div>



							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default EcoSystem