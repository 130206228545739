import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import Breadcumb from '../../Breadcumb/Main'

const EnterpriseApp = () => {
	const options = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 700,
		items: 1,
		margin: 0,
		nav: false,
		dots: false,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			576: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			}
		}
	}
	return (
		<>
			<Breadcumb Title="Enterprise Application" Breadcumb="PROJECT" />
			<section className="project-details section-padding--bottom section-padding--top">
				<div className="container">

					<h3 className="project-details__title">Purchase Requisition Enterprise Application</h3>
					<div className="project-details__content">
						<p>The app Tesla Digitech built in AWS with serverless Graphql, transform the way how an electrical company with revenue of millions rands handles purchase requisition traditionally into digital methods. This app simplifies the whole processes of purchase requisition and give visibility who has requested, how long it has taken to get it approved and delivered items to project sites with visuals.</p>
						<br /><span className="service-card-three__title">Key benefits</span><p>You can gain significant control on successful project delivery and revenue leakage caused by manpower wastage because of poor and traditional way of managing purchase requisition and order lifecycle.</p>
						<ul className="project-details__list"><span className="service-card-three__title">Enables</span>
							<li>
								<i className="fa fa-check-circle"></i>
								Site managers from 20 different sites across south Africa to submit purchase requisition electronically.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Contract manager to review, edit when necessary and approve items captured in purchase requisition.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Procurement manager to proceed with generating purchase order with different suppliers for the requisition approved by contract manager.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Store manager to validate each delivery item from supplier against a list in purchase order and upload delivery notes instantly.
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Account manager to track delivery status and proceed with payments to supplier. 
							</li>
						</ul>
					</div>
				</div>
			</section>




		</>
	)
}

export default EnterpriseApp