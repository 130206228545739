import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Home2 from '../components/Home2/Main'
import Home3 from '../components/Home3/Main'
import Navbar from '../components/Navbar/Main'
import CloudSolution from '../components/Services/CloudSolution/Main'
import CyberSecurity from '../components/Services/CyberSecurity/Main'
import DataAnalytics from '../components/Services/DataAnalytics/Main'
import EcoSystem from '../components/Services/EcoSystem/Main'
import RPA from '../components/Services/RPA/Main'
import Consulting from '../components/Services/Consulting/Main'
import ProductDevelopment from '../components/Services/ProductDevelopment/Main'
import Service1 from '../components/Services/Services1/Main'
import Service2 from '../components/Services/Services2/Main'
import EnterpriseApp from '../components/Project/EnterpriseApp/Main'
import Team from '../components/Team/Main'
import Partners from '../components/Partners/Main'


const Routing = () => {
    const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/home-02" || location.pathname === "/home-03"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])
    
    return (
        <>
            {homepage && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home-02" element={<Home2 />} />
                <Route path="/home-03" element={<Home3 />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/service-01" element={<Service1 />} />
                <Route path="/service-02" element={<Service2 />} />
                <Route path="/cyber-security" element={<CyberSecurity />} />
                <Route path="/cloud-solution" element={<CloudSolution />} />
                <Route path="/robotic-process-automation" element={<RPA />} />
                <Route path="/consulting" element={<Consulting />} />
                <Route path="/data-analytics-eco-system" element={<EcoSystem />} />
                <Route path="/product-development" element={<ProductDevelopment />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/data-analytics" element={<DataAnalytics />} />
                <Route path="/purchase-requisition-enterprise-application" element={<EnterpriseApp />} />
                <Route path="/partners" element={<Partners />} />

            </Routes>
            <Footer />
        </>
    )
}

export default Routing