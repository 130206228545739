import React from 'react'
import { Link } from 'react-router-dom'
import BG from '../../../assets/images/background/service-1-bg-1.png'

const ServiceSidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__item sidebar__item--category">
        <h3 className="sidebar__title">Services</h3>
        <ul className="sidebar__category">
          <li><Link to="/data-analytics">Data Analytics</Link></li>
          <li><Link to="/data-analytics-eco-system">Data-Analytics Eco System</Link></li>
          <li><Link to="/cloud-solution">Cloud Solution</Link></li>
          <li><Link to="/consulting">Consulting</Link></li>
          <li><Link to="/cyber-security">Cyber Security</Link></li>
          <li><Link to="/robotic-process-automation">RPA</Link></li>
          <li><Link to="/product-development">Product Development</Link></li>
        </ul>
      </div>

    </div>
  )
}

export default ServiceSidebar