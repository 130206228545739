import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/cse.jpg'

const CyberSecurity = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Cyber Security" Breadcumb="SERVICES" />
				<section className="section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
						</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>


	<section className="section-padding--bottom gray-bg testimonials-one background-repeat-no background-position-top-center">
        <div className="container">
		<h3 className="service-details__title">Cyber Security</h3>
			<div className="service-details__content">
			<p>In the digital world, cybersecurity is no longer just about compliance and risk mitigation, but it is imperative to have sustainable growth, since like most companies, cyber criminals too are on their own digital transformation journey.</p>
									
		</div>
          <div className="row gutter-y-30">
            <div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
                  <i className="fas fa-user-lock"></i>
                </div>
                <div className="testimonials-one-card__content">
				<h3 className="testimonials-one-card__title">Data Security</h3><br/>
				<div className="testimonials-one-card__text">Continuously raise the bar on securing data with various controls and features , including advanced access, encryption, logging  and monitoring.</div>

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
				<i className="fas fa-shield-alt"></i>
                </div>
                <div className="testimonials-one-card__content">
				<h3 className="testimonials-one-card__title">Cyber Protection</h3><br/>
				<div className="testimonials-one-card__text">Protect the business as it transforms—applying zero trust principles to secure the entire digital core.</div>

                </div>
              </div>
            </div>
			<div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
				<i className="fas fa-user-secret"></i>
                </div>
                <div className="testimonials-one-card__content">
				<h3 className="testimonials-one-card__title">Cyber Resilience</h3><br/>
				<div className="testimonials-one-card__text">Understand emerging threats and prepare and respond quickly to attacks with SIEM and SOAR.</div>

                </div>
              </div>
            </div>
			<div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
				<i className="fas fa-key"></i>
                </div>
                <div className="testimonials-one-card__content">
				<h3 className="testimonials-one-card__title">Governance, Risk and Compliance</h3><br/>
				<div className="testimonials-one-card__text">Manage risks, stay compliant with evolving regulatory mandates.</div>

                </div>
              </div>
            </div>
			<div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
				<i className="fas fa-user-shield"></i>
                </div>
                <div className="testimonials-one-card__content">
				<h3 className="testimonials-one-card__title">Vulnerability Management</h3><br/>
				<div className="testimonials-one-card__text">Closing the vulnerability gap with orchestration framework.</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
			</Suspense>
		</>
	)
}

export default CyberSecurity