import React from 'react'
import { Link } from 'react-router-dom'
import BG2 from '../../assets/images/projects/img1.jpg'
import BG3 from '../../assets/images/services/p2g.jpg'
import Breadcumb from '../Breadcumb/Main'

const Partners = () => {
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <Breadcumb Title="Partnership" Breadcumb="P2GT" />
            <section className="about-four section-padding--top">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-four__image">
                                <img src={BG2} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-four__content">
                                <div className="section-title ">
                                    <p className="section-title__text"><sup><i class="fa fa-quote-left" aria-hidden="true"></i></sup> Partnerships that grow together <sup><i class="fa fa-quote-right" aria-hidden="true"></i></sup> - P2GT</p>
                                </div>
                                <div className="about-four__text">Tesla Digitech is delighted to announce a partnership with Kea and Assistance to digitally transform your business, drive synergies and improve the end-to-end customer experience by leveraging proven capabilities.</div>
								<div className="about-four__text">Contact us now to discover how you can amplify your business transformation outcomes! </div>
								<div className="about-four__text">Continue to check our growing partnerships and other well established IT companies.</div>
                                <img src={BG3} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <br/>
           </>
    )
}

export default Partners