import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/css.jpg'

const CloudSolution = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Cloud Solution" Breadcumb="SERVICES" />
				<section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
								<h3 className="service-details__title">Cloud Solution</h3>
								<div className="service-details__content">
									<p>We support all data driven projects such as building Data Lake, ETL Process, Data warehousing, Developing Business Intelligence reports, and Machine Learning models in AWS and Azure.</p>
									
									<p>Support Homogeneous and heterogeneous database migration.</p>
									<p>Migrate your database and Analytics workloads to Cloud easily and securely.</p>
								</div>



							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default CloudSolution