import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/da.jpg'

const DataAnalytics = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Data Analytics" Breadcumb="SERVICES" />
				<section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
								<h3 className="service-details__title">Data Analytics</h3>
								<div className="service-details__content">
									<p>In today’s digital age, data is the backbone of any business. Our business model focuses on accelerating our data and analytics services that helps our customers have the competitive advantage amongst the industry peers.</p>
									
									<p>Build insight-driven reports and dashboards and take informed decisions with business intelligence. Derive the maximum value from your ML Investments by operationalizing and streamlining ML end-to- end across the model lifecycle to achieve desired performance.</p>
								</div>



							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default DataAnalytics