import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-light.png'


const Footer = () => {
    const location = useLocation();
    const [footerPadding, setfooterPadding] = useState(false);
    useEffect(() => {
      if(location.pathname === "/" || location.pathname === "/service-01"){
        setfooterPadding(true)
      }else{
        setfooterPadding(false)
      }
    }, [location])
     
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
}, [ ])
    
    return (
        <>
            <div className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo} width="119" alt="Cretech" />
                                </Link>
                                <p className="footer-widget__text">We have a passion for taking on challenges and creating new opportunities through the world of data.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Services</h3>
                                <ul className="footer-widget__links">
                                    <li>
                                        <Link to="">Data Analytics</Link>
                                    </li>
                                    <li>
                                        <Link to="">Cloud Solution</Link>
                                    </li>
                                    <li>
                                        <Link to="">Consulting</Link>
                                    </li>
                                    <li>
                                        <Link to="">Cyber Security</Link>
                                    </li>
                                    <li>
                                        <Link to="">Enterprise Applications</Link>
                                    </li>
                                    <li>
                                        <Link to="">RPA</Link>
                                    </li>
                                    <li>
                                        <Link to="">Product Development</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="tel:+27644636454">+27 65 353 2957</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="mailto:info@tesladigitech.com">info@tesladigitech.com</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        Unit 1 & 2 Rhino Industrial Patk, <br /> Eastleigh, Edenvale, South Africa - 1609.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; 2022 - 2023,  All Rights Reserved <span className="dynamic-year"></span> by Tesla DigiTech</p>
                </div>
            </div>
        </>
    )

}

export default Footer