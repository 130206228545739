import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import WOW from 'wowjs'
import m_logo from '../../assets/images/logo-light.png'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    const [search, setSearch] = useState(false)
    const [mobile, setmobile] = useState(false)

    const [menu, setmenu] = useState({})
    const [home, setHome] = useState(false)
    const [header, setheader] = useState(false)
    const [services, setpages] = useState(false)
    const [industries, setindustries] = useState(false)
    const [partners, setpartners] = useState(false)
    const [blog, setblog] = useState(false)

    const activeMenu = () => {
        if (path === "/" || path === "/home-02" || path === "/home-03") {
            setmenu({ home: true })
        } else if (path === "/about-us" ) {
            setmenu({ pages: true })
        } else if (path === "/data-analytics" || path === "/cloud-solution" || path === "/cyber-security" || path === "/data-analytics-eco-system" || path === "/consulting" || path === "/robotic-process-automation" || path === "/product-development") {
            setmenu({ services: true })
        } else if (path === "/projects" || path === "/project-details") {
            setmenu({ project: true })
        } else if (path === "/blog" || path === "/blog-details") {
            setmenu({ blog: true })
        } else if (path === "/contact-us") {
            setmenu({ contact: true })
        } else if (path === "/partners") {
            setmenu({ partners : true })
        }else {
            setmenu({ home: true })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path])

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Welcome to Tesla DigiTech, discover a world of potential using data!</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:info@tesladigitech.com">info@tesladigitech.com</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            Unit 1 & 2 Rhino Industrial Patk, Eastleigh, Edenvale, South Africa - 1609.
                        </li>
                    </ul>
                    
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} width="98" alt="tesladigitech" />
                        </Link>
                    </div>

                    

                    <div className="main-menu__right">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>

                        <ul className="main-menu__list">
                        <li >
                           

                        </li>
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>

                        </li>


                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="#">Services</Link>
                            <ul>
                                <li><Link to="/data-analytics">Data Analytics</Link></li>    
                                <li><Link to="/cloud-solution">Cloud Solution</Link></li>
                                <li><Link to="/consulting">Consulting</Link></li>
                                <li><Link to="/cyber-security">Cyber security</Link></li>
                                <li><Link to="/purchase-requisition-enterprise-application">Enterprise Applications</Link></li>
                                <li><Link to="/robotic-process-automation">RPA</Link></li>
                                <li><Link to="/product-development">Product Development</Link></li>
                            </ul>
                        </li>                       
                        <li className={`menu-item-has-children ${menu.industries && "current"}`}>
                            <Link to="#"><b>Industries</b></Link>
                            <ul>
                                <li><Link to="#">Banking & Financial Services</Link></li>
                                <li><Link to="#">Utilities</Link></li>
                                <li><Link to="#">Health care</Link></li>
                                <li><Link to="#" className="high_light">Manufacturing</Link></li>
                                <li><Link to="#">Retail</Link></li>
                                <li><Link to="#">Travel, Transport & Hospitality</Link></li>
                                           
                            </ul>
                        </li>
  

                        <li className={`menu-item-has-children ${menu.partners && "current"}`}>
                            <Link to="/partners">P2GT</Link>

                        </li>
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <Link to="/about-us">About Us</Link>

                        </li>
                       
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/contact-us">Contact Us     </Link>
                        </li>

                    </ul>
                        
                    </div>

                </div>
            </nav>

            <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <Link to="#" onClick={() => setmobile(false)} className="mobile-nav__close mobile-nav__toggler">
                        <span></span>
                        <span></span>
                    </Link>

                    <div className="logo-box">
                        <Link to="/" aria-label="logo image"><img src={m_logo} width="98" alt="tesla" /></Link>
                    </div>
                    <div className="mobile-nav__container"><ul className="mobile-menu__list">
                        <li className={`menu-item-has-children ${menu.home ? "current" : ""}`}>
                            <Link to="/" className={home ?"expanded": ""}>Home</Link>
                            
                        </li>
                        <li className={`menu-item-has-children ${menu.services ? "current" : ""}`}>
                            <Link to="#" className={services ?"expanded": ""}>Services<button aria-label="dropdown toggler" onClick={()=>setpages(!services)} className={services ?"expanded": ""}><i className="fa fa-angle-down"></i></button></Link>
                            {services &&
                            <ul style={{display:"block"}}>
                                <li><Link to="/data-analytics">Data Analytics</Link></li>    
                                <li><Link to="/cloud-solution">Cloud Solution</Link></li>
                                <li><Link to="/consulting">Consulting</Link></li>
                                <li><Link to="/cyber-security">Cyber security</Link></li>
                                <li><Link to="/purchase-requisition-enterprise-application">Enterprise Applications</Link></li>
                                <li><Link to="/robotic-process-automation">RPA</Link></li>
                                <li><Link to="/product-development">Product Development</Link></li>
                            </ul>}
                        </li>
                        <li className={`menu-item-has-children ${menu.industries ? "current" : ""}`}>
                            <Link to="#" className={industries ?"expanded": ""}>Industries<button aria-label="dropdown toggler" onClick={()=>setindustries(!industries)} className={industries ?"expanded": ""}><i className="fa fa-angle-down"></i></button></Link>
                            {industries &&
                            <ul style={{display:"block"}}>
                                <li><Link to="#">Banking & Financial Services</Link></li>
                                <li><Link to="#">Utilities</Link></li>
                                <li><Link to="#">Health care</Link></li>
                                <li><Link to="#">Manufacturing</Link></li>
                                <li><Link to="#">Retail</Link></li>
                                <li><Link to="#">Travel, Transport & Hospitality</Link></li>
                            </ul>}
                        </li>
                        <li><Link to="/partners">P2GT</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul></div>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-phone"></i>
                            <a href="tel:+27644636454">+27 64 463 6454</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:thaines.martin@tesladigitech.com">thaines.martin@tesladigitech.com</a>
                        </li>
                        <li>
                            <i className="fa fa-map-marker-alt"></i>
                            Unit 1 & 2 Rhino Industrial Patk, Eastleigh, Edenvale, South Africa - 1609.
                        </li>
                    </ul>

                </div>
            </div>
        </>
    )
}

export default Navbar