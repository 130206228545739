import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/rpa.jpg'

const RPA = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Robotic Process Automation - RPA" Breadcumb="SERVICES" />
				<section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
								<h3 className="service-details__title">Robotic Process Automation - RPA</h3>
								<div className="service-details__content">
									<p>Create intelligent automation and save time and costs by building tailored AI models.</p>
									
									<p>Gain a competitive advantage by linking all your data workflows.</p>
									<p>Automate daily repetitive manual intensive tasks.</p>
								</div>



							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default RPA