import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import img1 from '../../../assets/images/services/consulting.jpg'

const Consulting = () => {

	const [clicked, setClicked] = useState(0);
	

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				<Breadcumb Title="Consulting" Breadcumb="SERVICES" />
				<section className="section-padding--bottom section-padding--top service-details--page">
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src={img1} alt="" />
								</div>
								<h3 className="service-details__title">Consulting</h3>
								<div className="service-details__content">
								<p>Bring the right people together to drive transformation and build capabilities needed for sustainable advantage.</p>
								<p>Empower internal resources to build automated processes.</p>

								</div>



							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default Consulting